window.bannerSliderHandler = () => {
    document.addEventListener('DOMContentLoaded', () => {
        bannerSliderEvents();
    });

    const bannerSliderEvents = () => {
        const slidersContainers = document.querySelectorAll('.jsBannerSliderContainer');

        slidersContainers.forEach(sliderContainer => {
            const slider = sliderContainer.querySelector('.jsBannerSlider');
            const prevArrow = sliderContainer.querySelector('.jsBannerSliderPrevArrow');
            const nextArrow = sliderContainer.querySelector('.jsBannerSliderNextArrow');

            slider && $(slider).slick({
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1000,
                arrows: true,
                swipe: true,
                prevArrow: $(prevArrow),
                nextArrow: $(nextArrow),
            });
        });
    }
};
